

















import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { AlertComponentInterface } from "../components/interfaces";
import { LazyLoad } from "./../components/LazyLoad";

type RegisterStep = "register";

@Component({
  components: {
    RegisterForm: () =>
      LazyLoad(
        import(
          /* webpackChunkName: "register.form" */ "./../components/forms/RegisterForm.vue"
        )
      ),
  },
  metaInfo: {
    title: "Register",
  },
})
export default class Register extends Vue {
  @Prop({ default: {} })
  public query!: Record<string, any>;

  @Ref("alert")
  public readonly $alert!: AlertComponentInterface;

  @Ref("success")
  public readonly $success!: InstanceType<any>;

  public step: RegisterStep = "register";

  created() {
    console.log("Return url", this.returnUrl);
    console.log(
      "Return url is internal? ",
      this.$isInternalUrl(this.returnUrl)
    );
  }

  get signinUrl() {
    return this.$url(process.env.VUE_APP_SIGNIN_URL, this.query);
  }

  get returnUrl() {
    return this.query.continue ?? process.env.VUE_APP_HOME_URL;
  }

  onRegistrationDone() {
    this.completeRegistration();
    this.$redirect(this.returnUrl);
  }

  async completeRegistration() {
    this.$store.commit("SET_SIGNEDIN_STATUS", true);
    this.$store.commit("SET_CURRENT_USER", {});
  }
}
