
































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { AlertComponentInterface } from "../components/interfaces";
import { LazyLoad } from "./../components/LazyLoad";

type ResetStep = "forgot" | "verify" | "reset" | "complete" | "error";

@Component({
  components: {
    ForgotPasswordForm: () =>
      LazyLoad(
        import(
          /* webpackChunkName: "forgot-password.form" */ "./../components/forms/ForgotPasswordForm.vue"
        )
      ),
    ResetPasswordForm: () =>
      LazyLoad(
        import(
          /* webpackChunkName: "reset-password.form" */ "./../components/forms/ResetPasswordForm.vue"
        )
      ),
    SuccessRedirect: () =>
      LazyLoad(
        import(
          /* webpackChunkName: "success-redirect" */ "./../components/SuccessRedirect.vue"
        )
      ),
  },
  metaInfo: {
    title: "Forgot Password",
  },
})
export default class Forgot extends Vue {
  @Prop({ default: {} })
  public query!: Record<string, any>;

  @Prop({ required: true })
  public defaultCountry!: string;

  @Prop({ required: true })
  public countries!: string[];

  public actions = {
    forgot: process.env.VUE_APP_FORGOT_PASSWORD_ACTION,
    reset: process.env.VUE_APP_RESET_PASSWORD_ACTION,
    verify: process.env.VUE_APP_VERIFY_ACTION,
  };

  @Ref("alert")
  public readonly $alert!: AlertComponentInterface;

  @Ref("success")
  public readonly $success!: InstanceType<any>;

  @Ref("verifyForm")
  public readonly $verifyForm!: InstanceType<any>;

  public step: ResetStep = "forgot";

  public result: Record<"begin" | "verification" | "complete", any> = {
    begin: {},
    verification: {},
    complete: {},
  };

  created() {
    console.log("Return url", this.returnUrl);

    console.log(
      "Return url is internal? ",
      this.$isInternalUrl(this.returnUrl)
    );
  }

  get signinUrl() {
    return this.$url(process.env.VUE_APP_SIGNIN_URL, this.query);
  }

  get returnUrl() {
    return this.query.continue ?? process.env.VUE_APP_SIGNIN_URL;
  }

  get verifyTitle() {
    switch (this.result.begin.mode) {
      case "phone":
        return "Verify Your Phone Number";

      case "email":
        return "Verify Your Email";

      default:
        return "Verify Account";
    }
  }

  get metadata() {
    return {};
  }

  onForgotDone({ data }: any) {
    this.result.begin = data;
    this.step = "reset";
  }

  onResetDone() {
    this.step = "complete";
    this.$success.count();
  }
}
